/* eslint-disable complexity */
/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { STRING_TRUE } from '@/data/constants/catalog';
import {
	ProductAttribute,
	ProductAttributeValue,
	ProductType,
	ResponseProductAttribute,
	ResponseProductAttributeValue,
	Selection,
} from '@/data/types/Product';
import { get, isEqual } from 'lodash';

/**
 * index product attributes by their identifier property
 * @param attrs product attribute list
 */
export const getAttrsByIdentifier = (attrs: ResponseProductAttribute[] = []) =>
	attrs.reduce(
		(byIdentifier, attr) => ({
			...byIdentifier,
			[attr.identifier]: get(attr, 'values[0].identifier') as string,
		}),
		{} as Record<string, string>
	);

/**
 * searches for the index(es) (in `list`) of a `Record` that matches exactly (all entries)
 *   the `Record` specified in `fullAttr`. If not found, tries to find the first `Record`
 *   in `list` that has matching key-value pair with key specified by `key`
 *
 * @param fullAttr full set of key-value pairs representing a sku (key=attribute identifier, value=attribute value)
 * @param key the key to search for in `list` when matching partially
 * @param list contains the attributes of various skus indexed as key-value pairs
 * @returns exact and best, where each represents the index at which the exact or best candidate was found
 */
export const search = (
	fullAttr: Record<string, string>,
	key: string,
	list: Record<string, string>[]
) => {
	const exact = list.findIndex((s) => isEqual(s, fullAttr));
	const best = exact === -1 && key ? list.findIndex((s) => s[key] === fullAttr[key]) : -1;
	return { exact, best };
};

export const initializeFromSelectedItem = (sku: ProductType, quantity: number): Selection => {
	const attrs = sku.definingAttributes ?? [];
	const attrsByIdentifier = getAttrsByIdentifier(attrs);

	return {
		sku,
		quantity,
		attrsByIdentifier,
		buyable: sku.buyable === STRING_TRUE,
	};
};

const initialize = (input?: ProductType, toFind?: ProductAttribute[]): Selection | undefined => {
	if (input) {
		const skus = input.items?.length ? input.items : [input];
		const attrs = toFind ?? skus[0].definingAttributes ?? [];
		const attrsByIdentifier = getAttrsByIdentifier(attrs);
		const skusAsAttrs = skus.map((s) => getAttrsByIdentifier(s.definingAttributes));
		const { exact } = search(attrsByIdentifier, '', skusAsAttrs);
		const sku = skus[exact === -1 ? 0 : exact];

		return {
			sku,
			quantity: 1,
			attrsByIdentifier,
			buyable: sku.buyable === STRING_TRUE,
		};
	} else {
		return undefined;
	}
};

export const mapProductDetailsData = (
	catentry: ProductType | undefined,
	parentCE: ProductType | undefined
) => {
	const { type, definingAttributes } = catentry ?? {};
	const product = parentCE ?? catentry;
	let selection;
	if (parentCE) {
		selection = initialize(parentCE, definingAttributes);
	} else if (type === 'item' || type === 'variant' || type === 'package') {
		selection = initialize(catentry);
	} else {
		selection = { quantity: 1 };
	}
	// get the color attribute and its index
	const colorAttrData = product?.definingAttributes?.map((attribute, index) =>
		attribute.name === 'Color' ? { attribute, index } : undefined
	);
	// create new color attribute value list based on sku order
	if (colorAttrData && colorAttrData.length > 0) {
		const index = colorAttrData?.at(0)?.index;
		const newColorValueList: ResponseProductAttributeValue[] = [];
		product?.items?.forEach((sku) =>
			// <c:forEach var="skuAttr" items="${sku.attributes}">
			sku.attributes.forEach((skuAttr) => {
				// <c:if test="${skuAttr.uniqueID eq attribute.uniqueID}">
				if (skuAttr.id === colorAttrData.at(0)?.attribute.id) {
					// <c:forEach var="itemColorValue" items="${skuAttr.values}">
					skuAttr.values.forEach((itemColorValue) => {
						if (!newColorValueList.find((value) => value.id === itemColorValue.id)) {
							newColorValueList.push(itemColorValue);
						}
					});
				}
			})
		);
		// replace product color attribute values with the new sorted value list
		if (
			product &&
			product.definingAttributes &&
			typeof index === 'number' &&
			product.definingAttributes[index]
		) {
			product.definingAttributes[index].values = newColorValueList as ProductAttributeValue[];
		}
	}
	return { product, selection };
};
